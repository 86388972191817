@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amita:wght@400;700&family=Yatra+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
.BriefServices .container {
  padding: 80px 160px;
}
@media all and (min-width: 2000px) {
  .BriefServices .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .BriefServices .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .BriefServices .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .BriefServices .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .BriefServices .container {
    padding: 50px 16px;
  }
}
.BriefServices .container .row {
  display: flex;
  align-items: center;
  gap: 40px;
}
@media all and (max-width: 768px) {
  .BriefServices .container .row {
    flex-direction: column;
  }
}
.BriefServices .container .row .left-col {
  width: 35%;
  position: sticky;
  height: 100%;
  top: 35%;
  padding-bottom: 200px;
}
@media all and (max-width: 768px) {
  .BriefServices .container .row .left-col {
    width: 100%;
    position: static;
    height: initial;
    top: initial;
    padding-bottom: initial;
  }
}
.BriefServices .container .row .left-col h2 {
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  color: #161616;
  text-transform: uppercase;
}
@media all and (max-width: 1200px) {
  .BriefServices .container .row .left-col h2 {
    font-size: 34px;
  }
}
@media all and (max-width: 992px) {
  .BriefServices .container .row .left-col h2 {
    font-size: 32px;
  }
}
@media all and (max-width: 768px) {
  .BriefServices .container .row .left-col h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 576px) {
  .BriefServices .container .row .left-col h2 {
    font-size: 24px;
  }
}
.BriefServices .container .row .left-col p {
  font-size: 16px;
  line-height: 140%;
  color: #212529;
  font-family: "Noto Sans", sans-serif;
  font-weight: 300;
}
@media all and (max-width: 992px) {
  .BriefServices .container .row .left-col p {
    font-size: 16px;
  }
}
.BriefServices .container .row .right-col {
  width: 65%;
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
}
@media all and (max-width: 768px) {
  .BriefServices .container .row .right-col {
    width: 100%;
  }
}
@media all and (max-width: 576px) {
  .BriefServices .container .row .right-col {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
.BriefServices .container .row .right-col .card {
  background-color: #f3f2e7;
  padding: 24px 20px;
  min-height: 280px;
  transition: all 0.5s;
}
.BriefServices .container .row .right-col .card:hover {
  box-shadow: rgba(255, 244, 54, 0.4) 0px 8px 24px;
  transform: translateY(-6px);
}
.BriefServices .container .row .right-col .card img {
  filter: saturate(0.5);
}
.BriefServices .container .row .right-col .card h3 {
  font-size: 18px;
  padding-bottom: 20px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  padding-bottom: 0;
  padding: 14px 0;
}
@media all and (max-width: 576px) {
  .BriefServices .container .row .right-col .card h3 {
    font-size: 17px;
  }
}
.BriefServices .container .row .right-col .card p {
  font-size: 16px;
  line-height: 140%;
  color: #212529;
  font-family: "Noto Sans", sans-serif;
  font-weight: 300;
}
@media all and (max-width: 992px) {
  .BriefServices .container .row .right-col .card p {
    font-size: 16px;
  }
}
.BriefServices .container .row .right-col .card img {
  width: 50px;
}
.BriefServices .container .row .right-col .card.even {
  transform: translateY(60px);
  transition: all 0.5s;
}
.BriefServices .container .row .right-col .card.even:hover {
  box-shadow: rgba(255, 244, 54, 0.4) 0px 8px 24px;
  transform: translateY(54px);
}
@media all and (max-width: 576px) {
  .BriefServices .container .row .right-col .card.even {
    transform: translateY(0);
  }
  .BriefServices .container .row .right-col .card.even:hover {
    transform: translateY(-6px);
  }
}