@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amita:wght@400;700&family=Yatra+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
.CounterContainer {
  padding: 30px 160px;
  background-color: #f3f2e7;
}
@media all and (min-width: 2000px) {
  .CounterContainer {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .CounterContainer {
    padding: 30px 80px;
  }
}
@media all and (max-width: 992px) {
  .CounterContainer {
    padding: 30px 50px;
  }
}
@media all and (max-width: 768px) {
  .CounterContainer {
    padding: 30px 32px;
  }
}
@media all and (max-width: 576px) {
  .CounterContainer {
    padding: 20px 16px;
  }
}
.CounterContainer .counter-row {
  display: flex;
  justify-content: space-around;
  text-align: center;
}
.CounterContainer .counter-row span {
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  color: #161616;
  color: #161616;
}
@media all and (max-width: 1200px) {
  .CounterContainer .counter-row span {
    font-size: 34px;
  }
}
@media all and (max-width: 992px) {
  .CounterContainer .counter-row span {
    font-size: 32px;
  }
}
@media all and (max-width: 768px) {
  .CounterContainer .counter-row span {
    font-size: 30px;
  }
}
@media all and (max-width: 576px) {
  .CounterContainer .counter-row span {
    font-size: 24px;
  }
}
.CounterContainer .counter-row h3 {
  font-size: 18px;
  padding-bottom: 20px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  opacity: 0.4;
}
@media all and (max-width: 576px) {
  .CounterContainer .counter-row h3 {
    font-size: 17px;
  }
}