.Whatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  transition: 0.3s all ease-in;
}
.Whatsapp::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  bottom: -14px;
  right: -20px;
  background-color: rgba(0, 128, 0, 0.1);
  border-radius: 50%;
  animation: scale-up 1.4s ease-in-out infinite;
}
@media all and (max-width: 768px) {
  .Whatsapp::before {
    width: 80px;
    height: 80px;
    bottom: -10px;
    right: -15px;
  }
}
@media all and (max-width: 768px) {
  .Whatsapp {
    bottom: 14px;
    right: 14px;
  }
}
@keyframes scale-up {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: scale(0.92);
    opacity: 0;
  }
}
.Whatsapp:hover {
  right: 20px;
}
.Whatsapp img {
  width: 60px;
  transition: all 0.15s ease-in;
  z-index: 9;
  position: relative;
  opacity: 0.6;
}
.Whatsapp img:hover {
  transform: scale(1.08);
  opacity: 1;
}
@media all and (max-width: 768px) {
  .Whatsapp img {
    width: 50px;
  }
}