@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amita:wght@400;700&family=Yatra+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
.ContactForm .container {
  padding: 80px 160px;
}
@media all and (min-width: 2000px) {
  .ContactForm .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .ContactForm .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .ContactForm .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .ContactForm .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .ContactForm .container {
    padding: 50px 16px;
  }
}
.ContactForm .container h2 {
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  color: #161616;
  text-align: center;
  padding-bottom: 40px;
}
@media all and (max-width: 1200px) {
  .ContactForm .container h2 {
    font-size: 34px;
  }
}
@media all and (max-width: 992px) {
  .ContactForm .container h2 {
    font-size: 32px;
  }
}
@media all and (max-width: 768px) {
  .ContactForm .container h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 576px) {
  .ContactForm .container h2 {
    font-size: 24px;
  }
}
.ContactForm .container form {
  display: flex;
  flex-direction: column;
}
.ContactForm .container form .form-group {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 768px) {
  .ContactForm .container form .form-group {
    flex-direction: column;
  }
}
.ContactForm .container form .form-group:last-child {
  flex-direction: column;
}
.ContactForm .container form .form-group input,
.ContactForm .container form .form-group textarea {
  width: 50%;
  padding: 20px;
  font-size: 14px;
  border: none;
  outline: none;
  color: #777;
}
@media all and (max-width: 768px) {
  .ContactForm .container form .form-group input,
  .ContactForm .container form .form-group textarea {
    width: 100%;
  }
}
.ContactForm .container form .form-group input::placeholder,
.ContactForm .container form .form-group textarea::placeholder {
  color: #999;
}
.ContactForm .container form .form-group input:focus,
.ContactForm .container form .form-group textarea:focus {
  outline: none;
  border-color: #007bff;
}
.ContactForm .container form .form-group input:nth-child(1) {
  border-top: 1px solid #161616;
  border-right: 1px solid #161616;
}
@media all and (max-width: 768px) {
  .ContactForm .container form .form-group input:nth-child(1) {
    border-right: none;
  }
}
.ContactForm .container form .form-group input:nth-child(2) {
  border-top: 1px solid #161616;
}
.ContactForm .container form .form-group textarea {
  width: 100%;
  height: 150px;
}
.ContactForm .container form .form-group textarea:nth-child(1) {
  border-top: 1px solid #161616;
  border-bottom: 1px solid #161616;
}
.ContactForm .container form .button-section {
  display: flex;
  justify-content: right;
}
.ContactForm .container form .button-section button {
  display: inline-block;
  text-decoration: none;
  margin: 20px 0;
  padding: 14px 16px;
  color: #161616;
  background-color: #FFF436;
  transition: all 0.4s;
  position: relative;
  z-index: 2;
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  border: none;
  margin-top: 40px;
}
.ContactForm .container form .button-section button:hover {
  background-color: #FFF436;
  color: white;
}
.ContactForm .container form .button-section button:hover::before {
  transform: scaleX(1);
  z-index: -1;
}
.ContactForm .container form .button-section button::before {
  content: "";
  position: absolute;
  inset: 1px;
  background-color: #161616;
  transform: scaleX(0);
  z-index: -1;
  transition: all 0.4s;
}
.ContactForm .container form .button-section button img {
  margin-left: 2px;
  transform: translateY(3px);
  width: 16px;
}