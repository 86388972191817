@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amita:wght@400;700&family=Yatra+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
.ServiceList .container {
  padding: 80px 160px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
@media all and (min-width: 2000px) {
  .ServiceList .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .ServiceList .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .ServiceList .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .ServiceList .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .ServiceList .container {
    padding: 50px 16px;
  }
}
@media all and (max-width: 992px) {
  .ServiceList .container {
    grid-template-columns: 1fr 1fr;
  }
}
@media all and (max-width: 576px) {
  .ServiceList .container {
    grid-template-columns: 1fr;
  }
}
.ServiceList .container .card {
  background-color: #f3f2e7;
  padding: 20px;
  min-height: 340px;
  transition: all 0.4s;
}
.ServiceList .container .card:hover {
  box-shadow: rgba(255, 244, 54, 0.4) 0px 8px 24px;
  transform: translateY(-6px);
}
@media all and (max-width: 576px) {
  .ServiceList .container .card {
    min-height: 300px;
  }
}
.ServiceList .container .card .img-container {
  padding-bottom: 20px;
}
.ServiceList .container .card .img-container img {
  width: 50px;
  filter: saturate(0.5);
}
.ServiceList .container .card h3 {
  font-size: 18px;
  padding-bottom: 20px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
}
@media all and (max-width: 576px) {
  .ServiceList .container .card h3 {
    font-size: 17px;
  }
}
.ServiceList .container .card p {
  font-size: 16px;
  line-height: 140%;
  color: #212529;
  font-family: "Noto Sans", sans-serif;
  font-weight: 300;
}
@media all and (max-width: 992px) {
  .ServiceList .container .card p {
    font-size: 16px;
  }
}