@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amita:wght@400;700&family=Yatra+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
.TestimonialList {
  background-color: #ffffff;
}
.TestimonialList .container {
  padding: 80px 160px;
}
@media all and (min-width: 2000px) {
  .TestimonialList .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .TestimonialList .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .TestimonialList .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .TestimonialList .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .TestimonialList .container {
    padding: 50px 16px;
  }
}
.TestimonialList .container .top-content {
  text-align: center;
  padding: 30px 0 80px;
}
.TestimonialList .container .top-content h2 {
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  color: #161616;
}
@media all and (max-width: 1200px) {
  .TestimonialList .container .top-content h2 {
    font-size: 34px;
  }
}
@media all and (max-width: 992px) {
  .TestimonialList .container .top-content h2 {
    font-size: 32px;
  }
}
@media all and (max-width: 768px) {
  .TestimonialList .container .top-content h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 576px) {
  .TestimonialList .container .top-content h2 {
    font-size: 24px;
  }
}
.TestimonialList .container .top-content p {
  font-size: 16px;
  line-height: 140%;
  color: #212529;
  font-family: "Noto Sans", sans-serif;
  font-weight: 300;
}
@media all and (max-width: 992px) {
  .TestimonialList .container .top-content p {
    font-size: 16px;
  }
}
.TestimonialList .container .TestimonialList-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
@media all and (max-width: 992px) {
  .TestimonialList .container .TestimonialList-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media all and (max-width: 576px) {
  .TestimonialList .container .TestimonialList-container {
    grid-template-columns: 1fr;
  }
}
.TestimonialList .container .TestimonialList-container .card {
  background-color: #f3f2e7;
  margin: 14px;
  padding: 16px;
  min-height: 300px;
  transition: all 0.4s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.TestimonialList .container .TestimonialList-container .card:hover {
  box-shadow: rgba(255, 244, 54, 0.4) 0px 8px 24px;
  transform: translateY(-6px);
}
.TestimonialList .container .TestimonialList-container .card h3 {
  font-size: 18px;
  padding-bottom: 20px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  padding-bottom: 14px;
}
@media all and (max-width: 576px) {
  .TestimonialList .container .TestimonialList-container .card h3 {
    font-size: 17px;
  }
}
.TestimonialList .container .TestimonialList-container .card p {
  font-size: 16px;
  line-height: 140%;
  color: #212529;
  font-family: "Noto Sans", sans-serif;
  font-weight: 300;
}
@media all and (max-width: 992px) {
  .TestimonialList .container .TestimonialList-container .card p {
    font-size: 16px;
  }
}
.TestimonialList .container .TestimonialList-container .card .profile {
  display: flex;
  padding-top: 20px;
}
.TestimonialList .container .TestimonialList-container .card .profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.TestimonialList .container .TestimonialList-container .card .profile h3 {
  font-size: 17px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  margin-bottom: 20px;
  opacity: 0.8;
  color: #161616;
}