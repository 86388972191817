@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amita:wght@400;700&family=Yatra+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
.dot, .circle {
  position: fixed;
  pointer-events: none;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.dot.active, .circle.active {
  opacity: 1;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #FFF436;
  border-radius: 50%;
  z-index: 10000;
}

@media all and (max-width: 768px) {
  .dot, .circle {
    display: none;
  }
}

.circle {
  width: 60px;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.36);
  border-radius: 50%;
  z-index: 9999;
  transition: transform 0.2s ease-out;
  animation: scaleAnimation 2s infinite;
}

@keyframes scaleAnimation {
  0%, 100% {
    transform: translate(-50%, -50%) scale(0.8);
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
  }
}