@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amita:wght@400;700&family=Yatra+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
.Footer {
  background-color: #f3f2e7;
}
.Footer .container {
  padding: 30px 160px;
}
@media all and (min-width: 2000px) {
  .Footer .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .Footer .container {
    padding: 30px 80px;
  }
}
@media all and (max-width: 992px) {
  .Footer .container {
    padding: 30px 50px;
  }
}
@media all and (max-width: 768px) {
  .Footer .container {
    padding: 30px 32px;
  }
}
@media all and (max-width: 576px) {
  .Footer .container {
    padding: 20px 16px;
  }
}
.Footer .container .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}
.Footer .container .row .logo img {
  width: 120px;
}
.Footer .container .row ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
}
.Footer .container .row ul li {
  padding-right: 40px;
}
.Footer .container .row ul li a {
  text-decoration: none;
  color: #161616;
  transition: all 0.4s;
  display: inline-block;
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
}
.Footer .container .row ul li a:hover {
  filter: drop-shadow(5px 5px 10px #FFF436);
  transform: translateY(-3px);
}
.Footer .container .row .social-media ul li {
  padding-right: 24px;
}
.Footer .container .row .social-media ul li span {
  font-size: 18px;
  display: flex;
}
.Footer .container .row .social-media ul li span i {
  width: 50px;
  height: 50px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ffffff;
}