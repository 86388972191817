@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amita:wght@400;700&family=Yatra+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
.AboutUs .container {
  padding: 80px 160px;
  text-align: center;
}
@media all and (min-width: 2000px) {
  .AboutUs .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .AboutUs .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .AboutUs .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .AboutUs .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .AboutUs .container {
    padding: 50px 16px;
  }
}
.AboutUs .container h2 {
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  color: #161616;
}
@media all and (max-width: 1200px) {
  .AboutUs .container h2 {
    font-size: 34px;
  }
}
@media all and (max-width: 992px) {
  .AboutUs .container h2 {
    font-size: 32px;
  }
}
@media all and (max-width: 768px) {
  .AboutUs .container h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 576px) {
  .AboutUs .container h2 {
    font-size: 24px;
  }
}
.AboutUs .container p {
  font-size: 16px;
  line-height: 140%;
  color: #212529;
  font-family: "Noto Sans", sans-serif;
  font-weight: 300;
}
@media all and (max-width: 992px) {
  .AboutUs .container p {
    font-size: 16px;
  }
}