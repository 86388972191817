@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amita:wght@400;700&family=Yatra+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
.Map .container {
  padding: 80px 160px;
}
@media all and (min-width: 2000px) {
  .Map .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .Map .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .Map .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .Map .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .Map .container {
    padding: 50px 16px;
  }
}