@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amita:wght@400;700&family=Yatra+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
.ServiceIntro .container {
  padding: 80px 160px;
}
@media all and (min-width: 2000px) {
  .ServiceIntro .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .ServiceIntro .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .ServiceIntro .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .ServiceIntro .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .ServiceIntro .container {
    padding: 50px 16px;
  }
}
.ServiceIntro .container .top-content {
  text-align: center;
  padding: 40px 0 80px;
}
.ServiceIntro .container .top-content h2 {
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  color: #161616;
}
@media all and (max-width: 1200px) {
  .ServiceIntro .container .top-content h2 {
    font-size: 34px;
  }
}
@media all and (max-width: 992px) {
  .ServiceIntro .container .top-content h2 {
    font-size: 32px;
  }
}
@media all and (max-width: 768px) {
  .ServiceIntro .container .top-content h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 576px) {
  .ServiceIntro .container .top-content h2 {
    font-size: 24px;
  }
}
.ServiceIntro .container .top-content p {
  font-size: 16px;
  line-height: 140%;
  color: #212529;
  font-family: "Noto Sans", sans-serif;
  font-weight: 300;
}
@media all and (max-width: 992px) {
  .ServiceIntro .container .top-content p {
    font-size: 16px;
  }
}
.ServiceIntro .container .mySwiper .img-container {
  height: 500px;
}
@media all and (max-width: 576px) {
  .ServiceIntro .container .mySwiper .img-container {
    height: 400px;
  }
}
.ServiceIntro .container .mySwiper .img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ServiceIntro .container .mySwiper .swiper-button-prev {
  background-color: rgba(255, 255, 255, 0.8);
  width: 50px;
  height: 50px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  opacity: 1;
  transition: all 0.3s;
}
.ServiceIntro .container .mySwiper .swiper-button-prev::after {
  font-size: 24px;
  font-weight: 500;
  color: #161616;
}
.ServiceIntro .container .mySwiper .swiper-button-prev:hover {
  background-color: rgb(255, 255, 255);
}
.ServiceIntro .container .mySwiper .swiper-button-next {
  background-color: rgba(255, 255, 255, 0.8);
  width: 50px;
  height: 50px;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  opacity: 1;
  transition: all 0.3s;
}
.ServiceIntro .container .mySwiper .swiper-button-next::after {
  font-size: 24px;
  font-weight: 500;
  color: #161616;
}
.ServiceIntro .container .mySwiper .swiper-button-next:hover {
  background-color: #ffffff;
}
.ServiceIntro .container .mySwiper .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.ServiceIntro .container .mySwiper .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.ServiceIntro .container .mySwiper .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 6px;
}
.ServiceIntro .container .mySwiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #f3f2e7;
}
.ServiceIntro .container .mySwiper .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  width: fit-content;
  left: 50%;
  transform: translateX(-50%);
}