@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amita:wght@400;700&family=Yatra+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
.BreadCrumb {
  text-align: left;
  padding: 34px 160px 0;
}
@media all and (min-width: 2000px) {
  .BreadCrumb {
    max-width: 85%;
    margin: 34px auto;
  }
}
@media all and (max-width: 1200px) {
  .BreadCrumb {
    padding: 34px 120px 0;
  }
}
@media all and (max-width: 992px) {
  .BreadCrumb {
    padding: 34px 100px 0;
  }
}
@media all and (max-width: 768px) {
  .BreadCrumb {
    padding: 34px 60px 0;
  }
}
@media all and (max-width: 576px) {
  .BreadCrumb {
    padding: 34px 16px 0;
  }
}
.BreadCrumb ul {
  color: #161616;
  text-shadow: 2px black;
  z-index: 2;
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  display: flex;
  list-style: none;
}
@media all and (max-width: 576px) {
  .BreadCrumb ul {
    top: 55%;
  }
}
.BreadCrumb ul li {
  margin: 0 6px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 300;
  font-size: 18px;
}
.BreadCrumb ul li a {
  display: inline-block;
  text-decoration: none;
  color: #161616;
  text-decoration: none;
}
.BreadCrumb ul .nav {
  position: relative;
}
.BreadCrumb ul .nav::after {
  content: "";
  position: absolute;
  bottom: -6px;
  right: 0;
  transform: translateX(10%);
  width: 100%;
  background-color: #FFF436;
  height: 2px;
}
.BreadCrumb ul li:nth-child(2)::before {
  content: "/ ";
  padding: 3px;
  color: #161616;
}