* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body{
  cursor: none;
}

/* .cursor-dot{
  width: 5px;
  height: 5px;
  background-color: white;
} */

