@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amita:wght@400;700&family=Yatra+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
.HeroSection {
  position: relative;
}
.HeroSection .container {
  padding: 80px 160px;
}
@media all and (min-width: 2000px) {
  .HeroSection .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .HeroSection .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .HeroSection .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .HeroSection .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .HeroSection .container {
    padding: 50px 16px;
  }
}
.HeroSection .container .top-content {
  padding: 40px 0;
}
.HeroSection .container .top-content h2 {
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  color: #161616;
  text-align: center;
  text-transform: uppercase;
}
@media all and (max-width: 1200px) {
  .HeroSection .container .top-content h2 {
    font-size: 34px;
  }
}
@media all and (max-width: 992px) {
  .HeroSection .container .top-content h2 {
    font-size: 32px;
  }
}
@media all and (max-width: 768px) {
  .HeroSection .container .top-content h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 576px) {
  .HeroSection .container .top-content h2 {
    font-size: 24px;
  }
}
.HeroSection .container .top-content p {
  font-size: 16px;
  line-height: 140%;
  color: #212529;
  font-family: "Noto Sans", sans-serif;
  font-weight: 300;
  text-align: center;
}
@media all and (max-width: 992px) {
  .HeroSection .container .top-content p {
    font-size: 16px;
  }
}
.HeroSection .container .img-container {
  display: flex;
  justify-content: center;
  padding: 50px 0;
  width: 100%;
}
.HeroSection .container .img-container img {
  width: 100%;
  transition: all 0.5s;
  max-height: 700px;
  object-fit: contain;
  object-position: center;
}
.HeroSection .container .img-container img:hover {
  filter: drop-shadow(5px 5px 12px #FFF436);
  transform: translateY(-3px);
}
.HeroSection .container .brief-about {
  padding: 40px 0 0;
  text-align: center;
}
.HeroSection .container .brief-about h2 {
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  color: #161616;
}
@media all and (max-width: 1200px) {
  .HeroSection .container .brief-about h2 {
    font-size: 34px;
  }
}
@media all and (max-width: 992px) {
  .HeroSection .container .brief-about h2 {
    font-size: 32px;
  }
}
@media all and (max-width: 768px) {
  .HeroSection .container .brief-about h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 576px) {
  .HeroSection .container .brief-about h2 {
    font-size: 24px;
  }
}
.HeroSection .container .brief-about p {
  font-size: 16px;
  line-height: 140%;
  color: #212529;
  font-family: "Noto Sans", sans-serif;
  font-weight: 300;
}
@media all and (max-width: 992px) {
  .HeroSection .container .brief-about p {
    font-size: 16px;
  }
}
.HeroSection .container .brief-about a {
  display: inline-block;
  text-decoration: none;
  margin: 20px 0;
  padding: 14px 16px;
  color: #161616;
  background-color: #FFF436;
  transition: all 0.4s;
  position: relative;
  z-index: 2;
  margin: 40px 0 0;
}
.HeroSection .container .brief-about a:hover {
  background-color: #FFF436;
  color: white;
}
.HeroSection .container .brief-about a:hover::before {
  transform: scaleX(1);
  z-index: -1;
}
.HeroSection .container .brief-about a::before {
  content: "";
  position: absolute;
  inset: 1px;
  background-color: #161616;
  transform: scaleX(0);
  z-index: -1;
  transition: all 0.4s;
}