@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amita:wght@400;700&family=Yatra+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
.Navbar .header {
  display: flex;
  justify-content: space-between;
  padding: 6px 160px;
  transition: all 0.3s;
  z-index: 199;
  width: 100%;
  backdrop-filter: none;
  align-items: center;
}
@media all and (min-width: 2000px) {
  .Navbar .header {
    padding: 6px 11%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .Navbar .header {
    padding: 6px 80px;
  }
}
@media all and (max-width: 992px) {
  .Navbar .header {
    padding: 6px 50px;
  }
}
@media all and (max-width: 768px) {
  .Navbar .header {
    padding: 6px 32px;
  }
}
@media all and (max-width: 576px) {
  .Navbar .header {
    padding: 6px 16px;
  }
}
.Navbar .header .logo {
  position: relative;
  z-index: 2;
}
.Navbar .header .logo img {
  width: 100px;
}
@media all and (max-width: 1200px) {
  .Navbar .header .logo img {
    width: 80px;
  }
}
@media all and (max-width: 576px) {
  .Navbar .header .logo img {
    width: 70px;
  }
}
.Navbar .header .nav-menu {
  z-index: 99;
}
.Navbar .header .nav-menu .nav-links {
  list-style: none;
  display: flex;
}
@media all and (max-width: 768px) {
  .Navbar .header .nav-menu .nav-links {
    position: fixed;
    visibility: hidden;
    transition: all 0.7s;
    flex-direction: column;
    height: 100%;
    width: 100%;
    opacity: 0;
    left: 40%;
    right: 0;
    top: 0;
    background: rgba(22, 22, 22, 0.8);
    backdrop-filter: blur(10px);
    z-index: 99;
    padding-top: 15%;
    transform: translateX(100%);
  }
}
.Navbar .header .nav-menu .nav-links .appointment-li {
  padding: 0 0 0 6px;
}
.Navbar .header .nav-menu .nav-links li {
  position: relative;
}
@media all and (min-width: 768px) {
  .Navbar .header .nav-menu .nav-links li {
    padding: 10px 20px;
  }
}
@media all and (max-width: 1100px) {
  .Navbar .header .nav-menu .nav-links li {
    padding: 10px 11px;
  }
}
@media all and (max-width: 992px) {
  .Navbar .header .nav-menu .nav-links li {
    padding: 10px 12px;
  }
}
@media all and (max-width: 768px) {
  .Navbar .header .nav-menu .nav-links li {
    padding: 14px 20px !important;
  }
}
.Navbar .header .nav-menu .nav-links li i {
  font-size: 12px;
  top: -2px;
  position: relative;
}
.Navbar .header .nav-menu .nav-links li a {
  text-decoration: none;
  color: #161616;
  text-transform: uppercase;
  transform-origin: left;
  transition: 0.3s;
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 3px;
  position: relative;
}
.Navbar .header .nav-menu .nav-links li a:hover::before {
  transform: scale(1);
  opacity: 1;
}
.Navbar .header .nav-menu .nav-links li a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -5px;
  z-index: -1;
  background-color: #FFF436;
  transition: all 400ms ease-in-out;
  transform: scale(0.2);
  transform-origin: left;
  opacity: 0;
}
@media all and (max-width: 768px) {
  .Navbar .header .nav-menu .nav-links li a {
    text-decoration: none;
    color: #f3f2e7;
    transform-origin: left;
    font-family: "Noto Sans", sans-serif;
    font-weight: 400;
    position: relative;
    text-transform: capitalize;
    font-size: 28px;
  }
}
@media all and (max-width: 576px) {
  .Navbar .header .nav-menu .nav-links li a {
    font-size: 24px;
  }
}
@media all and (max-width: 768px) {
  .Navbar .header .nav-menu .nav-links.active {
    visibility: visible;
    opacity: 1;
    height: 100%;
    transform: translateX(0%);
  }
  .Navbar .header .nav-menu .nav-links.active li a:hover {
    transform: translateX(100%);
  }
}
.Navbar .header .nav-menu .nav-links.active ~ .menu-button label span {
  background: #f3f2e7;
}
.Navbar .header .nav-menu .menu-button {
  display: none;
  z-index: 99;
  position: relative;
  padding: 14px 0;
}
@media all and (max-width: 768px) {
  .Navbar .header .nav-menu .menu-button {
    display: flex;
    justify-content: flex-end;
  }
}
.Navbar .header .nav-menu .menu-button label {
  display: flex;
  flex-direction: column;
  width: 30px;
  cursor: pointer;
}
.Navbar .header .nav-menu .menu-button label span {
  background: #161616;
  border-radius: 10px;
  height: 3px;
  margin: 3px 0;
  transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
.Navbar .header .nav-menu .menu-button span:nth-of-type(1) {
  width: 50%;
}
.Navbar .header .nav-menu .menu-button span:nth-of-type(2) {
  width: 100%;
}
.Navbar .header .nav-menu .menu-button span:nth-of-type(3) {
  width: 75%;
}
.Navbar .header .nav-menu .menu-button input[type=checkbox] {
  display: none;
}
.Navbar .header .nav-menu .menu-button input[type=checkbox]:checked ~ span:nth-of-type(1) {
  transform-origin: bottom;
  transform: rotatez(45deg) translate(5px, 0px);
}
.Navbar .header .nav-menu .menu-button input[type=checkbox]:checked ~ span:nth-of-type(2) {
  transform-origin: top;
  transform: rotatez(-45deg);
}
.Navbar .header .nav-menu .menu-button input[type=checkbox]:checked ~ span:nth-of-type(3) {
  transform-origin: bottom;
  width: 50%;
  transform: translate(13px, -5px) rotatez(45deg);
}
@media all and (max-width: 768px) {
  .Navbar .header .nav-menu .overlay.active {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    visibility: visible;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    /* Adjust the alpha value to control transparency */
    z-index: 9;
    backdrop-filter: blur(3px);
    transition: opacity 0.4s ease-in;
  }
  .Navbar .header .nav-menu .overlay {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s ease-in;
  }
}
.Navbar .header .get-in-touch a {
  display: inline-block;
  text-decoration: none;
  margin: 20px 0;
  padding: 14px 16px;
  color: #161616;
  background-color: #FFF436;
  transition: all 0.4s;
  position: relative;
  z-index: 2;
  margin: 6px 0;
}
.Navbar .header .get-in-touch a:hover {
  background-color: #FFF436;
  color: white;
}
.Navbar .header .get-in-touch a:hover::before {
  transform: scaleX(1);
  z-index: -1;
}
.Navbar .header .get-in-touch a::before {
  content: "";
  position: absolute;
  inset: 1px;
  background-color: #161616;
  transform: scaleX(0);
  z-index: -1;
  transition: all 0.4s;
}
.Navbar .header .get-in-touch a img {
  margin-left: 1px;
  transform: translateY(3px);
  width: 16px;
}
@media all and (max-width: 768px) {
  .Navbar .header .get-in-touch {
    display: none;
  }
}
.Navbar .header.active {
  position: fixed;
  z-index: 199;
  width: 100%;
  padding: 10px 160px;
  align-items: center;
  animation: slide-down 700ms;
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(5px);
  box-shadow: -1px -12px 17px 0px rgba(0, 0, 0, 0.75);
}
@media all and (min-width: 2000px) {
  .Navbar .header.active {
    max-width: 100%;
    padding: 10px 11%;
  }
}
@media all and (max-width: 1200px) {
  .Navbar .header.active {
    padding: 10px 80px;
  }
}
@media all and (max-width: 992px) {
  .Navbar .header.active {
    padding: 10px 50px;
  }
}
@media all and (max-width: 768px) {
  .Navbar .header.active {
    padding: 10px 32px;
    background-color: rgba(250, 251, 252, 0.9647058824);
    backdrop-filter: none;
  }
}
@media all and (max-width: 576px) {
  .Navbar .header.active {
    padding: 10px 16px;
  }
}
@keyframes slide-down {
  0% {
    transform: translateY(-400%);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
.Navbar .header.active .logo {
  position: relative;
  z-index: 2;
}
.Navbar .header.active .logo img {
  width: 80px;
}
@media all and (max-width: 1200px) {
  .Navbar .header.active .logo img {
    width: 70px;
  }
}
@media all and (max-width: 576px) {
  .Navbar .header.active .logo img {
    width: 60px;
  }
}
.Navbar .header.active .nav-menu {
  z-index: 99;
}
.Navbar .header.active .nav-menu .nav-links {
  z-index: 99;
}
@media all and (min-width: 768px) {
  .Navbar .header.active .nav-menu .nav-links a {
    color: #161616;
  }
}
.Navbar .header.active .nav-menu .menu-button label span {
  background: #161616;
}